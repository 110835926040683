<template>
    <div>
       <div v-if="policyInfo" :class="['policy', {'hidden':show != true}]">
           <button class="hide" @click="show = false">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17 1L1 17" stroke="black" stroke-width="2"/>
                    <path d="M1 1L17 17" stroke="black" stroke-width="2"/>
                </svg>
           </button>
            <a href="#" class="change link collapsed" data-toggle="collapse" data-target="#searchcollapse" aria-expanded="false" aria-controls="#searchcollapse" @click="$emit('searchcollapse', showTourismSearchForm = !showTourismSearchForm)">{{ stepBtn }}</a>
            <div class="title d-lg-block">Параметри пошуку</div>
            <hr class="d-none d-lg-block">
            <div class="specific d-lg-block">
                <b>Країна</b>
                <p>{{policyInfo.country.name}}</p>
            </div>
            <div  class="specific d-lg-block">
                <b>Термін поїздки</b>
                <p>{{policyInfo.days}}</p>
            </div>
            <div class="specific d-lg-block">
               <b>Тип страхування</b>
               <p>{{showType(policyInfo.yearProgram)}}</p>
            </div>
            <div class="specific d-lg-block">
               <b>Кількість та вік туристів</b>
               <p>{{policyInfo.tourists}}</p>
            </div>
            <div v-if="offerInfo && step != 'result'" class="specific d-lg-block">
               <b>Ліміт покриття</b>
               <p>{{offerInfo.limit}} {{ offerInfo.limit_currency | currencySign }}</p>
            </div>
            <div v-if="offerInfo && step != 'result'" class="specific d-lg-block">
               <b>Вартість:</b>
               <p>{{FullPrice}} грн.</p>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        data: () => ({
            show: true,
            showTourismSearchForm: false,
            step: false,
            FullPrice: 0,
        }),
        props: ['dataStep','dataFullPrice'],

        watch: {
            dataFullPrice:function (newVAl,oldVal) {
                this.FullPrice = newVAl;
            }
        },
        created() {

        },
        mounted: function () {
            this.fetchData();
        },

        computed: {
            stepBtn(){
                const data = {'result':'Змінити','steps':''};
                return data[this.step];
            },
            policyInfo() {
                return this.$store.state.tourismStore.searchTourismParams;
            },
            offerInfo() {
                return this.$store.getters.GET_TOURISM_INFO_OFFER;
            },
        },

        methods: {
            showType(type) {
                if (!type) {
                    return 'Одноразовий'
                } else {
                    return `Річна`
                }
            },
            scrollTo(item){
                let el = item;
                $('html,body').animate({
                    scrollTop: $(el).offset().top + 150
                }, 1000);
            },
            fetchData() {
                this.step = this.dataStep || 'result';
                this.FullPrice = this.dataFullPrice || 0;
            },
        }
    }
</script>
