export default {
    data(){
        return {
            defaultOffersSortType: null,
        }
    },
    beforeMount() {
        this.getOffersSortType()
    },
    methods: {
        getOffersSortType() {
            axios.get('api/get-offers-sort-type').then(response => {
                this.defaultOffersSortType = response.data
                if (this.defaultOffersSortType) {
                    let option = document.querySelector(`option[value=${this.defaultOffersSortType}]`)
                    option.parentElement.value = this.defaultOffersSortType
                    let icon = option.querySelector('i')
                    icon.classList.remove('pin')
                    icon.classList.add('pin-fill')
                }
            })
        },
        setOffersSortType({target}) {
            let type = target.parentElement.parentElement.getAttribute('data-value')

            if (type) {
                let icons = target.parentElement.parentElement.parentElement.querySelectorAll('i');
                icons.forEach(item => {
                    item.classList.add('pin')
                    item.classList.remove('pin-fill')
                })

                target.parentElement.parentElement.querySelector('i').classList.remove('pin')
                target.parentElement.parentElement.querySelector('i').classList.add('pin-fill')

                axios.get(`api/set-offers-sort-type/${type}`).then(response => {
                    this.defaultOffersSortType = response.data
                })
            }
        }
    }
}
